import {
  CartDetails,
  ConsumerPreferences,
  MerchantEligibilityOpt,
} from '@chargeafter/payment-types';
import {
  MessageEligibilityOpenModalPayload,
  ModalMessagePayload,
  SDKConfig,
  SDKMessagePayload,
} from '@chargeafter/types-sdk';
import { PromotionsResponse } from '@chargeafter/types-session';

import { browserSessionId } from './../../utils/browserSessionId';
import {
  MerchantActions,
  OpenIdentificationCollectionModal,
  OpenPaymentsModal,
  OpenPromotionsModal,
} from './manager.model';

import { environment } from '../../../environments/environment';
import { logger } from '../../logger';
import { serialize } from '../../utils/serialize';
import { refWindow } from '../../utils/windowRef';
import {
  createIframe,
  getLendersFromModalAction,
  initModalAction,
  onCompletionCallbackCalled,
  openEligibilityModal,
  openModalAction,
  createModalListeners,
  populateContainerWithModal,
  listenToPopState,
} from '../actions/manager.actions';
import {
  createListenersEvents,
  listenToModalRemoveFromDom,
  removeListenersEvents,
} from '../message-listener/message.listener';
import { ModalData, ModalsData, NarrowAction } from '../models/manager.model';

let lastFocusedElement: HTMLElement | null = null;
const filename = 'modal.manager';
const refDocument = document;
let modals: ModalsData = {} as ModalsData;
let sessionId = '';

type IframeDataParams = {
  id?: string;
  name?: string;
  container?: string | HTMLElement;
};

const createModal = async ({
  modalId,
  config,
  type = 'promotions',
  iframeData = {},
}: {
  type?: SDKMessagePayload['type'];
  modalId: string;
  config: SDKConfig;
  iframeData?: IframeDataParams;
}) => {
  const { container, name = modalId, id = environment.iframe.id } = iframeData;

  logger.log(`creating modal for ${modalId}`, {
    function: 'createModal',
    filename,
    isModalExist: !!modals[modalId],
  });

  let modal: HTMLIFrameElement;

  if (modals[modalId]?.iframe) {
    await populateContainerWithModal(modals[modalId].iframe, container);
    modal = modals[modalId]?.iframe;
  } else {
    modals[modalId] = {
      ...(modals[modalId] || {}),
      isInitialized: false,
      isPresenting: true,
      id: modalId,
    };

    modal = await createIframe({
      id,
      container,
      name,
      url: `${environment.iframe.url}/v3/?key=${config?.apiKey || ''}&storeId=${
        config?.storeId || ''
      }`,
    });

    const isUnderTest = !!refWindow?.['__isUnderCaTest__'];

    await initModalAction(
      {
        type,
        id: modalId,
        browserSessionId: browserSessionId.get,
        caConfig: {
          ...serialize(config || {}),
          browserSessionId: browserSessionId.get,
          origin: location.origin,
          ...(isUnderTest && { isUnderTest }),
        },
      },
      modal
    );

    if (modals[modalId]) {
      modals[modalId].iframe = modal;
      modals[modalId].isInitialized = true;
    }
  }

  listenToModalRemoveFromDom(modalId, name);

  return modal;
};

export const modalPresent = async ({
  payload,
  config,
  isCheckout = false,
  merchantActions,
}: {
  payload:
    | OpenPaymentsModal
    | OpenPromotionsModal
    | OpenIdentificationCollectionModal;
  config: SDKConfig;
  isCheckout?: boolean;
  merchantActions?: MerchantActions;
}) => {
  lastFocusedElement = refDocument.activeElement as HTMLElement;
  let containerSelector = null;

  if ('paymentsData' in payload && 'opt' in payload.paymentsData) {
    containerSelector = payload.paymentsData.opt.containerSelector;

    if (
      'lenderId' in payload.paymentsData.opt &&
      payload.paymentsData.opt?.lenderId
    ) {
      if (!sessionId)
        await modalGetDirectLenders(config, payload.configurationSessionId);

      payload.paymentsData.sessionId = sessionId;
      payload.paymentsData.lenderSessionId =
        modals[payload.paymentsData.opt.lenderId]?.data?.lenderSessionId;
    }
  }

  const modalId = 'global';

  const { iframe, isPresenting = false } = modals[modalId] || {};

  if (isPresenting) {
    logger.log(`${payload.type} modal is already presented`, {
      function: 'present',
      filename,
      payload,
      isPresenting,
      isModalExist: !!iframe,
    });
    return;
  }

  const modal = await createModal({
    modalId: modalId,
    type: payload.type,
    config,
    ...(containerSelector && {
      iframeData: {
        id: environment.iframe.id,
        container: containerSelector,
        name: payload.type,
      },
    }),
  });

  if (!modals[modalId]) {
    return;
  }

  const controller: AbortController = createModalListeners({
    modalData: {
      ...modals[modalId],
      payload: payload as Exclude<SDKMessagePayload, 'source'>,
    },
    isCheckout,
    merchantActions,
    widgetData:
      payload.type === 'promotions' ? payload?.widget?.widgetData : undefined,
  });

  logger.log(`starting to present modal ${payload.type}`, {
    function: 'present',
    filename,
    payload,
    isPresenting,
    isModalExist: !!modal,
  });

  modals[modalId].isPresenting = true;

  const merchantOverflow = refDocument.body.style.overflow;

  modal.style.display = 'block';
  refDocument.body.style.overflow = 'hidden';

  const unListenToPopstate = listenToPopState(modal);

  merchantActions?.onModalOpen?.();

  const data = await openModalAction({ ...payload, id: 'global' }, modal);

  unListenToPopstate();

  data?.type === 'payments' &&
    'data' in data &&
    (await onCompletionCallbackCalled({
      isCheckout,
      callbackData: data.data,
      onComplete: merchantActions?.onComplete,
      presentCallback: merchantActions?.callback,
      widgetData:
        payload.type === 'promotions' ? payload?.widget?.widgetData : undefined,
    }));

  controller?.abort();

  if (modals[modalId]) modals[modalId].isPresenting = false;

  modal.style.display = 'none';
  refDocument.body.style.overflow = merchantOverflow;
  lastFocusedElement?.focus?.();
  return ('data' in data && data.data) || null;
};

export const modalPresentEligibility = async ({
  lenderId,
  config,
  container,
  cartDetails,
  callback,
  preferences,
  configurationSessionId,
}: {
  lenderId: string;
  cartDetails: CartDetails;
  merchant?: PromotionsResponse['merchant'];
  container?: string | HTMLElement;
  callback: MerchantEligibilityOpt['callback'];
  config: SDKConfig;
  preferences?: ConsumerPreferences;
  configurationSessionId?: string;
}) => {
  if (!sessionId) await modalGetDirectLenders(config, configurationSessionId);

  if (!modals[lenderId]) {
    logger.error(
      `cannot create eligibility modal with the lenderId ${lenderId} because its is not supported`,
      {
        function: 'modalPresentEligibility',
        filename,
        printOriginalToConsole: true,
      }
    );
    return;
  }

  lastFocusedElement = refDocument.activeElement as HTMLElement;

  const { data: { lenderName } = { lenderName: null } }: ModalData =
    modals[lenderId];

  const modal = await createModal({
    modalId: lenderId,
    type: 'eligibility',
    config,
    iframeData: {
      id: environment.iframe.id + '-' + lenderId,
      container,
      name: lenderName,
    },
  });

  if (!modals[lenderId]) {
    return;
  }

  modal.style.display = 'block';

  const payload: Omit<MessageEligibilityOpenModalPayload, 'action' | 'source'> =
    {
      type: 'promotions',
      configurationSessionId,
      eligibilityData: {
        inlineMode: true,
        sessionId,
        opt: {
          cartDetails,
          lenderName,
          lenderId,
          preferences,
        },
      },
    };

  const controller = createListenersEvents(lenderId, modal, {
    ELIGIBILITY_CHECK_STATUS_RECEIVED: {
      onMessage: async ({
        eligible,
      }: NarrowAction<
        ModalMessagePayload,
        'ELIGIBILITY_CHECK_STATUS_RECEIVED'
      >) => {
        callback?.(eligible);
      },
    },
    LENDER_SESSION_ID: {
      onMessage: async ({
        lenderId,
        lenderSessionId,
      }: NarrowAction<ModalMessagePayload, 'LENDER_SESSION_ID'>) => {
        modals[lenderId].data.lenderSessionId = lenderSessionId;
      },
    },
  });

  modals[lenderId].isPresenting = true;
  await openEligibilityModal({ ...payload, id: lenderId }, modal);
  modals[lenderId].isPresenting = false;
  controller?.abort();
  modal.style.display = 'none';
  lastFocusedElement?.focus?.();
};

export const modalDispose = () => {
  logger.log(`disposing modal data`, {
    function: 'dispose',
    filename,
  });

  for (const modalData of Object.values(modals)) {
    modalData.iframe?.remove();
  }

  modals = {} as ModalsData;
  removeListenersEvents();
};

export const modalGetDirectLenders = async (
  config: SDKConfig,
  configurationSessionId?: string
) => {
  const modalId = 'global';

  if (modals?.[modalId]?.isPresenting) {
    throw new Error(
      'getDirectLenders cannot be called while modal is presenting'
    );
  }

  await createModal({
    modalId: modalId,
    type: 'payments',
    config,
  });

  if (!modals[modalId]) {
    return;
  }

  modals[modalId].isPresenting = false;
  const { iframe: modal } = modals[modalId];
  const data = await getLendersFromModalAction(
    {
      type: 'payments',
      id: 'global',
      configurationSessionId,
    },
    modal
  );

  if ('data' in data && 'status' in data.data) {
    throw data.data.status;
  }

  if ('directLenders' in data) {
    sessionId = data.sessionId;

    for (const lender of data.directLenders) {
      modals[lender.id] = {
        data: { lenderId: lender.id, lenderName: lender.name },
      };
    }

    return data.directLenders;
  }
};

/**
 * for testing proposes only
 * @deprecated - for testing only
 * @private
 */
let testingData: {
  modals: typeof modals;
  sessionId: string;
  createModal: typeof createModal;
};

if (process.env.NODE_ENV === 'test') {
  testingData = {
    get modals() {
      return modals;
    },
    set modals(modalToSet: ModalsData) {
      modals = modalToSet;
    },
    get sessionId() {
      return sessionId;
    },

    set sessionId(session: string) {
      sessionId = session;
    },
    createModal,
  };
}

export { testingData };

import { WidgetData } from '@chargeafter/types-widgets';

const lendersTemplateWidgetsType: WidgetData['widgetType'][] = [
  'default-template',
  'multi-lender',
];

const widgetButtonTypes: WidgetData['buttonType'][] = [
  'large-generic',
  'small-generic',
  'medium-generic',
  'large-square',
  'small-square',
  'medium-square',
];

const widgetTypes: WidgetData['widgetType'][] = [
  'default',
  'product-widget',
  // Fallback for old integration
  'product-widget-syf',
  'product-widget-line-of-credit',
  'product-widget-line-of-credit-by-promo',
  'product-widget-installments',
  'banner-vertical',
  'banner-horizontal',
  'banner-vertical-upto-6months',
  'banner-horizontal-upto-6months',
  'banner-vertical-upto-48months',
  'banner-horizontal-upto-48months',
  'line-of-credit',
  'textual',
  ...lendersTemplateWidgetsType,
];

const staticWidgetTypes: WidgetData['widgetType'][] = [
  'banner-horizontal',
  'banner-horizontal-upto-48months',
  'banner-horizontal-upto-6months',
  'banner-vertical',
  'banner-vertical-upto-48months',
  'banner-vertical-upto-6months',
  'default',
];

const lineOfCreditTypes: WidgetData['widgetType'][] = [
  'product-widget-line-of-credit',
  'line-of-credit',
  'product-widget-syf',
  'product-widget-line-of-credit-by-promo',
];

const dynamicWidgetTypes: WidgetData['widgetType'][] = [
  'banner-horizontal',
  'banner-horizontal-upto-48months',
  'banner-horizontal-upto-6months',
  'banner-vertical',
  'banner-vertical-upto-48months',
  'banner-vertical-upto-6months',
  'default',
  'textual',
  'product-widget',
];

const financeUrlRequiredWidgetTypes: WidgetData['widgetType'][] = [
  'product-widget-line-of-credit',
  'product-widget-syf',
  'product-widget-installments',
  'line-of-credit',
];

export {
  lineOfCreditTypes,
  financeUrlRequiredWidgetTypes,
  staticWidgetTypes,
  widgetTypes,
  widgetButtonTypes,
  lendersTemplateWidgetsType,
  dynamicWidgetTypes,
};

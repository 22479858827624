import { CartDetails, ConsumerDetails } from '@chargeafter/payment-types';
import { MerchantCheckoutOpt } from '@chargeafter/types-sdk';
import { WidgetData } from '@chargeafter/types-widgets';
import {
  dynamicWidgetTypes,
  financeUrlRequiredWidgetTypes,
  lineOfCreditTypes,
  widgetButtonTypes,
  widgetTypes,
} from '@chargeafter/widget-kit';

import { logger } from '../logger';

const filename = 'validations';
const INPUT_REGEXP =
  /^=|(\b)(on\S+)(\s*)=|javascript|<(|\/|[^\\/>][^>]+|\/[^>][^>]+)>/gi;

export const CA_OLD_SHOPIFY_HOST = 'shopify.chargeafter.com';
export const CA_SHOPIFY_HOST = 'sh.chargeafter.com';

export const isNumberValid = (numberProp?: unknown) => {
  return (
    ((typeof numberProp === 'string' && numberProp) ||
      typeof numberProp === 'number') &&
    !isNaN(+numberProp)
  );
};

export const isStringValid = (stringProp?: unknown) => {
  return typeof stringProp === 'string' && !!stringProp;
};

export const isObjectValid = (objectProp?: unknown) => {
  return (
    typeof objectProp === 'object' &&
    !(objectProp instanceof Array) &&
    objectProp !== null
  );
};

export const isObjectNullableValid = (objectProp?: unknown) => {
  return (
    isObjectValid(objectProp) || objectProp === null || objectProp === undefined
  );
};

export const isArrayValid = (arrayProp?: unknown) => {
  return Array.isArray(arrayProp) && !!arrayProp.length;
};

export const isFunctionValid = (functionProp?: unknown) => {
  return typeof functionProp === 'function';
};

export const isWidgetValid = (widget: WidgetData) => {
  const loggerData = {
    filename,
    function: 'isWidgetValid',
    printOriginalToConsole: true,
  };

  const {
    widgetType,
    widgetItemPrice,
    widgetItemSku,
    widgetFinancingPageUrl,
    buttonType,
    widgetProfile,
  } = widget;

  const isDynamicWidget = dynamicWidgetTypes.includes(widgetType);
  const isCheckoutButton = buttonType;

  if (
    !widgetTypes.includes(widgetType) &&
    !widgetButtonTypes.includes(buttonType) &&
    !widgetProfile
  ) {
    logger.error(
      `Cannot render widget, the type "${
        widgetType || buttonType
      }" is not supported, the supported types are "${(!buttonType
        ? widgetTypes
        : widgetButtonTypes
      ).join(', ')}"`,
      { ...loggerData, isSeverityWarn: true }
    );
    return false;
  }

  if (isDynamicWidget || isCheckoutButton) return true;

  if (!widgetItemPrice || !widgetItemSku || typeof widgetItemSku !== 'string') {
    logger.error(
      `Cannot render widget${
        widgetItemSku ? ' ' + widgetItemSku : ''
      }, because ${
        !widgetItemSku && !widgetItemPrice
          ? 'at least one of the mandatory attributes "data-widget-item-sku" and "data-widget-item-price"'
          : `the mandatory attribute ${
              !widgetItemPrice
                ? '"data-widget-item-price"'
                : '"data-widget-item-sku"'
            }`
      } ${
        typeof widgetItemSku !== 'string' &&
        typeof widgetItemSku !== 'undefined' &&
        widgetItemSku !== null
          ? 'is not of type string'
          : "were not added to the widget's element"
      }`,
      { ...loggerData, isSeverityWarn: true }
    );
    return false;
  }

  if (
    financeUrlRequiredWidgetTypes.indexOf(widgetType) > -1 &&
    !widgetFinancingPageUrl
  ) {
    logger.error(
      `Cannot render widget (SKU: "${widgetItemSku}"), because the mandatory attribute "data-widget-financing-page-url" was not added to the widget's element`,
      { ...loggerData, isSeverityWarn: true }
    );

    return false;
  }

  if (isNaN(+widgetItemPrice) || +widgetItemPrice < 0) {
    logger.error(
      `Cannot render widget, item price is not in the correct format should be bigger or equal to 0. received:${widgetItemPrice}`,
      { ...loggerData, isSeverityWarn: true }
    );

    return false;
  }

  return true;
};

export const validateValues = (object: Record<string, unknown>) => {
  let isValid = true;

  if (object) {
    for (const key in object) {
      const val = object[key];

      if (typeof val === 'object' && !(val instanceof Array)) {
        isValid = validateValues(val as Record<string, unknown>);
      } else if (
        typeof val === 'string' &&
        new RegExp(INPUT_REGEXP).test(val as string)
      ) {
        isValid = false;
        break;
      }
    }
  }

  return isValid;
};

export const validateWidgetData = (widgetData: WidgetData) => {
  // Checking if the url for finance is without the protocols (http/s) if not correcting
  if (
    widgetData.widgetFinancingPageUrl &&
    /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi.test(
      widgetData.widgetFinancingPageUrl
    ) &&
    !/^http:|https:/.test(widgetData.widgetFinancingPageUrl)
  ) {
    widgetData.widgetFinancingPageUrl = `https://${widgetData.widgetFinancingPageUrl}`;
  } else if (widgetData.widgetFinancingPageUrl) {
    // Checking if the url for finance is relative if it is correcting to full url
    const parser = document.createElement('a');

    parser.href = widgetData.widgetFinancingPageUrl;
    widgetData.widgetFinancingPageUrl = `${
      /^http:|https:/.test(parser.protocol)
        ? parser.protocol
        : location.protocol
    }//${parser.host}${
      /^\//.test(parser.pathname) ? parser.pathname : `/${parser.pathname}`
    }${parser.search}`;
  }

  // Correcting price that include none number symbols e.g $4000 -> 4000
  if (typeof widgetData.widgetItemPrice === 'string')
    widgetData.widgetItemPrice = widgetData.widgetItemPrice.replace(
      /[^0-9.-]+/g,
      ''
    );

  if (lineOfCreditTypes.includes(widgetData.widgetType)) {
    widgetData.widgetType = 'product-widget-line-of-credit';
  }

  return { ...widgetData };
};

export const validateAddress = ({
  shippingAddress,
  billingAddress,
}: ConsumerDetails = {}) => {
  const isValid = !!(
    isStringValid(shippingAddress?.city) &&
    isStringValid(shippingAddress?.zipCode) &&
    isStringValid(shippingAddress?.state) &&
    isStringValid(shippingAddress?.line1) &&
    isStringValid(billingAddress?.city) &&
    isStringValid(billingAddress?.zipCode) &&
    isStringValid(billingAddress?.state) &&
    isStringValid(billingAddress?.line1)
  );

  if (!isValid) {
    logger.error(
      `some data is missing from shipping and/or billing addresses, while updateCallBack was not provided`,
      {
        function: 'validateAddress',
        filename,
        isSeverityWarn: true,
        printOriginalToConsole: true,
      }
    );
  }

  return isValid;
};

export const validateManaged = (managed?: { token: string }) => {
  if (managed) {
    const isValid = isStringValid(managed.token);

    if (!isValid) {
      logger.error(
        `The application cannot be continued because token is missing`,
        {
          function: 'validateManaged',
          filename,
          printOriginalToConsole: true,
        }
      );
    }

    return isValid;
  }

  return true;
};

export const validateCartDetails = (cartDetails: CartDetails) => {
  if (!cartDetails) {
    logger.error(
      `can't continue application because cartDetails was not provided`,
      {
        function: 'isMerchantOptValid',
        filename,
        printOriginalToConsole: true,
      }
    );
    return false;
  }

  const { items, discounts } = cartDetails;

  if (!isArrayValid(items)) {
    logger.error('cart details provided without items', {
      function: 'validateCartDetails',
      filename,
      printOriginalToConsole: true,
    });

    return false;
  }

  const badCartItems: {
    items: CartDetails['items'] | undefined;
    discounts: CartDetails['discounts'] | undefined;
  } = {
    items: undefined,
    discounts: undefined,
  };

  // according to Gill Segev we should support 0 values from merchant. I check only for undefined since if the merchant passed me null, it was intentionally perhaps.
  badCartItems.items = items.filter(
    (item) =>
      !isStringValid(item.name) ||
      !isNumberValid(item.price) ||
      !isNumberValid(item.quantity) ||
      !isStringValid(item.sku) ||
      !isObjectNullableValid(item.warranty) ||
      (item.warranty
        ? !isStringValid(item.warranty?.name) ||
          !isNumberValid(item.warranty?.price) ||
          !isStringValid(item.warranty?.sku)
        : false)
  );

  badCartItems.discounts = discounts?.filter(
    (discount) =>
      !isStringValid(discount.name) || !isNumberValid(discount.amount)
  );

  if (badCartItems.items?.length || badCartItems.discounts?.length) {
    if (badCartItems.items.length) {
      logger.error(
        `the following cart Items missing basic or warranty data: ${JSON.stringify(
          badCartItems.items
        )}`,
        {
          filename,
          function: 'validateCartDetails',
          printOriginalToConsole: true,
        }
      );
    }

    if (badCartItems?.discounts?.length) {
      logger.error(
        `the following cart discounts missing basic data: ${JSON.stringify(
          badCartItems.discounts
        )}`,
        {
          filename,
          function: 'validateCartDetails',
          printOriginalToConsole: true,
        }
      );
    }

    return false;
  }

  return true;
};

export const validateCartAmount = ({
  totalAmount,
  taxAmount,
  shippingAmount,
  discounts = [],
  items = [],
}: CartDetails) => {
  if (
    location?.host?.toLowerCase().includes(CA_SHOPIFY_HOST) ||
    location?.host?.toLowerCase().includes(CA_OLD_SHOPIFY_HOST)
  )
    return true;

  const discountPrice = discounts.reduce(
    (curr, discount) => +curr + +discount.amount,
    0
  );

  const itemPrice = items.reduce(
    (curr, item) =>
      curr + (+item.price * +item.quantity + +(item.warranty?.price || 0)),
    0
  );

  const calculatedTotalAmount = (
    +taxAmount +
    +shippingAmount +
    +itemPrice -
    +discountPrice
  ).toFixed(2);
  const isValid = (+totalAmount).toFixed(2) === calculatedTotalAmount;

  if (!isValid) {
    logger.error(
      `totalAmount is not matching the cart details, totalAmount received:${totalAmount.toFixed(
        2
      )}, totalAmount calculated:${calculatedTotalAmount}`,
      {
        function: 'validateCartAmount',
        filename,
        printOriginalToConsole: true,
      }
    );
  }

  return isValid;
};

/**
 * @description checks if cart details object is valid and the total amount is matching
 */
export const validateCartDetailsFully = (
  cartDetails: CartDetails,
  callback: MerchantCheckoutOpt['callback']
) => {
  if (!validateCartDetails(cartDetails)) {
    callback(undefined, undefined, {
      code: 'MISSING_CHECKOUT_DATA',
      message: 'Partial or missing cartDetails',
    });

    return false;
  }

  if (!validateCartAmount(cartDetails)) {
    callback(undefined, undefined, {
      code: 'TOTAL_AMOUNT_MISMATCH',
      message: 'totalAmount is not matching the cart details',
    });
    return false;
  }

  return true;
};

export const isMerchantOptValid = (opt: MerchantCheckoutOpt) => {
  if (!isObjectValid(opt)) {
    logger.error(`can't continue application because opt was not passed`, {
      function: 'isMerchantOptValid',
      filename,
      printOriginalToConsole: true,
    });

    return false;
  }

  if (!opt.isCheckout) {
    return true;
  }

  if (!isFunctionValid(opt.callback)) {
    logger.error(
      `can't continue application because "callBack" was not provided in opt`,
      {
        function: 'isMerchantOptValid',
        filename,
        printOriginalToConsole: true,
      }
    );

    return false;
  }

  if (!validateManaged(opt.managed)) {
    opt.callback(undefined, undefined, {
      code: 'MISSING_CHECKOUT_DATA',
      message: 'Token is missing from managed object',
    });
    return false;
  }

  if (!isIntentValid(opt)) {
    opt.callback(undefined, undefined, {
      code: 'MISSING_CHECKOUT_DATA',
      message: 'Intent object is invalid',
    });
    return false;
  }

  if (opt.managed || opt.applicationId || opt.sessionToken) {
    return true;
  }

  if (
    !isFunctionValid(opt.onDataUpdate) &&
    (!isObjectValid(opt.consumerDetails) ||
      !validateAddress(opt.consumerDetails))
  ) {
    opt.callback(undefined, undefined, {
      code: 'MISSING_CHECKOUT_DATA',
      message:
        'Partial or missing billing and shipping address, and no onDataUpdate to allow modification',
    });
    return false;
  }

  if (!validateCartDetailsFully(opt.cartDetails, opt.callback)) {
    return false;
  }

  if (!validateValues(opt.consumerDetails as Record<string, unknown>)) {
    logger.error('consumer details contains illegal char', {
      function: 'isMerchantOptValid',
      filename,
      printOriginalToConsole: true,
    });

    opt.callback(undefined, undefined, {
      code: 'ILLEGAL_CHAR_PROVIDED',
      message: 'consumer details contains illegal char',
    });
    return false;
  }

  return true;
};

export const roundDecimalForCart = (cartDetails: CartDetails) => {
  if (cartDetails) {
    cartDetails.taxAmount = +(+cartDetails.taxAmount).toFixed(2);
    cartDetails.shippingAmount = +(+cartDetails.shippingAmount).toFixed(2);
    cartDetails.totalAmount = +(+cartDetails.totalAmount).toFixed(2);
    cartDetails.discounts?.forEach((discount) => {
      discount.amount = +(+discount.amount).toFixed(2);
    });
    cartDetails.items.forEach((item) => {
      item.price = +(+item.price).toFixed(2);

      if (item.warranty)
        item.warranty.price = +(+item.warranty?.price)?.toFixed(2);
    });
  }
};

export const isIntentValid = (opt: MerchantCheckoutOpt) =>
  'intent' in opt
    ? isStringValid(opt.intent.lenderId) &&
      isStringValid(opt.intent.type) &&
      (opt.intent.type === 'Authenticate'
        ? isStringValid(opt.sessionToken)
        : true)
    : true;

import {
  CartDetails,
  Config,
  MerchantIdentificationCollectionOpt,
} from '@chargeafter/payment-types';
import {
  ChargeAfter,
  MerchantApplyOpt,
  MerchantCheckoutOpt,
} from '@chargeafter/types-sdk';
import { UpdatePromotionsPayload } from '@chargeafter/types-session';

import { logger } from './logger';
import {
  update,
  dispose,
  getDirectLenders,
  openLink,
  present,
  sdkInit,
  updateConfig,
} from './sdk';

import { environment } from '../environments/environment';

const filename = 'sdk';

// eslint-disable-next-line no-restricted-globals
const refWindow = window;

let isApplyDeprecatedErrorPrinted = false;
let isCheckoutDeprecatedErrorPrinted = false;
let isPromotionalDeprecatedErrorPrinted = false;

export const SDK: ChargeAfter = {
  cfg: (refWindow.caConfig ||
    (refWindow.ChargeAfter as unknown as Record<string, unknown>)
      ?.cfg) as Config,
  get payments() {
    return { present } as ChargeAfter['payments'];
  },

  get promotions() {
    return {
      present: (arg: string | { lenderId?: string; sku?: string }) =>
        present(
          'promotion',
          typeof arg === 'string' ? arg : arg.sku || arg.lenderId
        ),
      openLink: openLink, // No promo - learn more (we open new tab with finance page url)
      update,
    };
  },

  /**
   * @deprecated please use property "payments" instead and use "present("apply", opt)"
   */
  get apply() {
    if (!isApplyDeprecatedErrorPrinted) {
      logger.warn(
        'The property "apply" is deprecated please use property "payments" instead and use "present("apply", opt)"',
        {
          function: 'apply',
          filename,
          printOriginalToConsole: true,
          isConsoleOnly: true,
        }
      );

      isApplyDeprecatedErrorPrinted = true;
    }

    return {
      present: (opt: MerchantApplyOpt = {}) =>
        present('apply', { ...opt, isCheckout: false }),
    };
  },

  /**
   * @deprecated please use property "payments" instead and use "present("checkout", opt)"
   */
  get checkout() {
    if (!isCheckoutDeprecatedErrorPrinted) {
      logger.warn(
        'The property "checkout" is deprecated please use property "payments" instead and use "present("checkout", opt)"',
        {
          function: 'checkout',
          filename,
          printOriginalToConsole: true,
          isConsoleOnly: true,
        }
      );

      isCheckoutDeprecatedErrorPrinted = true;
    }

    return {
      present: (opt: MerchantCheckoutOpt) =>
        present('checkout', { ...opt, isCheckout: true }),
    };
  },

  /**
   * @deprecated deprecated please use property "promotions" instead
   */
  get promotionalWidget() {
    if (!isPromotionalDeprecatedErrorPrinted) {
      logger.warn(
        'The property "promotionalWidget" is deprecated please use property "promotions" instead',
        {
          function: 'promotional',
          filename,
          printOriginalToConsole: true,
          isConsoleOnly: true,
        }
      );

      isPromotionalDeprecatedErrorPrinted = true;
    }

    const promotions = (this as ChargeAfter).promotions;

    const promotionalApi: ChargeAfter['promotionalWidget'] = {
      ...promotions,
      update: (data?: { items?: UpdatePromotionsPayload }, language?: string) =>
        update(data?.items, language),
      rebuild: async () => {
        await update();
        this.cfg?.onLoaded?.();
      },
    };

    return promotionalApi;
  },

  get identification() {
    return {
      present: (opt: MerchantIdentificationCollectionOpt) =>
        present('identification-collection', opt) as ReturnType<
          ChargeAfter['identification']['present']
        >,
    };
  },

  getDirectLenders,

  get eligibility() {
    return {
      createWidget: async (opt: {
        lenderId: string;
        container: string | HTMLElement;
        callback: (eligible: boolean) => Promise<void> | void;
        cartDetails: CartDetails;
      }) => {
        present('eligibility', opt);
      },
    };
  },

  get poweredByLogo() {
    return `${environment.cdnUrl}/chargeafter/powered-by.svg`;
  },

  get init() {
    return (config: Config) => {
      this.cfg = config;
      return sdkInit(config);
    };
  },

  get updateConfig() {
    return (config: Partial<Config>) => {
      this.cfg = { ...this.cfg, ...config };
      return updateConfig(config);
    };
  },

  get dispose() {
    return dispose;
  },
};

export interface LoggerConfig {
  production: boolean;
  logsUrl: string;
  appName: string;
  version?: string;
  loggingCreds?: { key: string; projectId: string };
  environmentName?: string;
  browserSessionId?: string;
  deviceSessionId?: string;
  sessionId?: string;
  sentryConfig?: SentryConfig;
  logLevel?: LogLevel;
  correlationId?: string;
  origin?: string;
}

export type SentryConfig = {
  dsn: string;
  version: string;
  auto?: boolean;
  tracing?: boolean;
  replays?: boolean;
  environment?: string;
};

export interface LogData {
  function: string;
  filename: string;
  printOriginalToConsole?: boolean;
  isConsoleOnly?: boolean;
  isSeverityWarn?: boolean;
  [key: string]: unknown;
}

export enum LogLevel {
  OFF,
  TRACE,
  DEBUG,
  INFO,
  LOG,
  WARN,
  ERROR,
  FATAL,
}

export type Log = { server?: [string, LogData]; console?: unknown[] };

export type TempBuffer = { logLevel: LogLevel; log: Log }[];

export const LogSeverity = {
  [LogLevel.OFF]: 'Off',
  [LogLevel.DEBUG]: 'Debug',
  [LogLevel.INFO]: 'Info',
  [LogLevel.TRACE]: 'Info',
  [LogLevel.LOG]: 'Info',
  [LogLevel.WARN]: 'Warning',
  [LogLevel.ERROR]: 'Error',
  [LogLevel.FATAL]: 'Critical',
};

type LogsTypes = 'debug' | 'info' | 'log' | 'warn' | 'error';

export const LogsType: { [key: number]: LogsTypes } = {
  [LogLevel.OFF]: 'debug',
  [LogLevel.DEBUG]: 'debug',
  [LogLevel.INFO]: 'info',
  [LogLevel.TRACE]: 'log',
  [LogLevel.LOG]: 'log',
  [LogLevel.WARN]: 'warn',
  [LogLevel.ERROR]: 'error',
  [LogLevel.FATAL]: 'error',
};

export interface FormatData {
  message: string;
  logData: LogData;
  module?: string;
  logMessage?: string;
}

export type LoggerFunction = (message: string, args: LogData) => void;
export type LoggerGroupFunction = (args: unknown[]) => void;
export type LoggerLogFunction = (
  message: string | LogData,
  args?: LogData | undefined
) => void;

export type LoggerService = {
  version: string;
  log: (message: string, args: LogData) => void;
  info: (message: string, args: LogData) => void;
  warn: (message: string, args: LogData) => void;
  error: (message: string, args: LogData) => void;
};

import { detect } from 'detect-browser';

import { filterProps, format } from './log.formatter';
import { handleServerLogs, sendLogsToServer } from './log.sender';
import { LogData, LogLevel, LoggerConfig, LogsType } from './logger.interface';
import { refConsole } from './utils';

type LogsExtraData = {
  config: LoggerConfig;
  isInitialized: boolean;
  merchantName: string;
};

type GetDataFunction = () => LogsExtraData;

let logsBuffer: { logLevel: LogLevel; log: LogData; message: string }[] = [];

const addLogToBuffer = (logLevel: LogLevel, message: string, log: LogData) => {
  logsBuffer.push({ logLevel, log, message });
};

export const flushBuffer = (data: LogsExtraData, isKeepalive = false) => {
  logsBuffer.forEach(({ logLevel, log, message }) => {
    print(data, logLevel, message, log);
  });

  logsBuffer = [];

  sendLogsToServer(data.config, isKeepalive);
};

const print = (
  data: LogsExtraData,
  logLevel: LogLevel,
  message: string,
  log: LogData
) => {
  const { config, merchantName } = data;
  const browser = detect();

  const formattedLog = format({
    ...log,
    browserSessionId: config.browserSessionId,
    deviceSessionId: config.deviceSessionId,
    correlationId: config.correlationId,
    browserName: browser?.name,
    browserVersion: browser?.version,
    sessionId: config.sessionId,
    appName: config.appName,
    origin: config.origin,
    merchantName,
    message,
  });

  // print the log to the console
  if (
    (!config.production ||
      [LogLevel.ERROR, LogLevel.FATAL].includes(logLevel)) &&
    formattedLog.console
  ) {
    const logType = LogsType[logLevel];

    refConsole[logType](...formattedLog.console);
  }

  // sends the log to the server
  if (formattedLog.server) {
    handleServerLogs(config, logLevel, formattedLog.server);
  }
};

export const makeLogHandler = (
  logLevel: LogLevel,
  getData: GetDataFunction
) => {
  return (message: string, log: LogData) => {
    const data = getData();
    const { config, isInitialized } = data;

    if (!isInitialized) {
      refConsole.error(
        `Logger is not initialized, called console.${LogsType[logLevel]} with message:`,
        message,
        'and log data:',
        log
      );
    }

    if (!log || !log.filename || !log.function) {
      throw new Error('Log data is required');
    }

    if ([LogLevel.ERROR, LogLevel.FATAL].includes(logLevel)) {
      console.error(new Error(message), filterProps(true, log));
    } else {
      if (
        (typeof message !== 'string' &&
          (message as LogData)?.printOriginalToConsole) ||
        log?.printOriginalToConsole
      ) {
        const newArgs = filterProps(true, log);
        const logType = LogsType[logLevel];

        refConsole[logType](message, ...Object.values(newArgs));
      }
    }

    const logData = {
      message,
      log: {
        ...log,
        timestamp: Date.now(),
      },
    };

    if ((!config.browserSessionId && !config.sessionId) || !isInitialized) {
      return addLogToBuffer(logLevel, message, logData.log);
    }

    print(data, logLevel, message, logData.log);
  };
};

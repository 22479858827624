export const promiseMiddleware = async (
  callback?: (...args: unknown[]) => Promise<unknown> | void,
  args: unknown[] = []
): Promise<{ status: 'success' | 'failure'; value: unknown }> => {
  try {
    const result = await callback?.(...args);

    return new Promise((resolve) =>
      resolve({ status: 'success', value: result })
    );
  } catch (e) {
    return new Promise((resolve) => resolve({ status: 'failure', value: e }));
  }
};

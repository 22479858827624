import { BaseModalData, SDKConfig } from '@chargeafter/types-sdk';
import { PromotionalInfoWidgetItem, Widgets } from '@chargeafter/types-session';

let widgets: Widgets = {};
let config: SDKConfig;
let isInitialized = false;
let sdkInitializedPromise: Promise<void> | undefined;
let basePromotionModalData: Omit<BaseModalData, 'promotion'>;

const itemsToUpdate: Map<string, PromotionalInfoWidgetItem> = new Map();

export const store = {
  get widgets() {
    return widgets;
  },
  set widgets(value: Widgets) {
    widgets = value;
  },

  get config() {
    return config;
  },

  set config(value: SDKConfig) {
    config = value;
  },

  get isInitialized() {
    return isInitialized;
  },

  set isInitialized(value: boolean) {
    isInitialized = value;
  },

  get basePromotionModalData() {
    return basePromotionModalData;
  },

  set basePromotionModalData(value: Omit<BaseModalData, 'promotion'>) {
    basePromotionModalData = value;
  },

  get sdkInitializedPromise() {
    return sdkInitializedPromise;
  },

  set sdkInitializedPromise(value: Promise<void> | undefined) {
    sdkInitializedPromise = value;
  },

  get itemsToUpdate(): Map<string, PromotionalInfoWidgetItem> {
    return itemsToUpdate;
  },
};

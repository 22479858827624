import {
  CompletionApplyData,
  CompletionCheckoutData,
  OnApplicationCreatedGlobal,
  OnCompleteCallback,
  OnDataUpdateCallBack,
  OnDataUpdateCallBackData,
} from '@chargeafter/payment-types';
import {
  ApplyCallback,
  Callback,
  CheckoutCallback,
  MessageCallbackCalledPayload,
  MessageDataUpdatePayload,
  ModalMessagePayload,
  OnDataUpdate,
  SDKMessagePayload,
} from '@chargeafter/types-sdk';

import { environment } from '../../../environments/environment';
import { DBUY } from '../../externalUrlManager/dbuy';
import { logger } from '../../logger';
import { setFeatureFlags } from '../../utils/featureFlags';
import { getInlineIframeStyle } from '../../utils/getInlineIframeStyle';
import { loadExternalScript } from '../external/external.actions';
import {
  createListenersEvents,
  createMessageFunction,
} from '../message-listener/message.listener';
import {
  MerchantActions,
  OpenPromotionsModal,
} from '../modal-manager/manager.model';
import { ModalData, NarrowAction } from '../models/manager.model';

// eslint-disable-next-line no-restricted-globals
const refWindow = window;
const filename = 'modal.actions';
const refDocument = document;

type CreateIframePayload = {
  id: string;
  name: string;
  container?: string | HTMLElement;
  style?: string;
  url?: string;
};

export const populateContainerWithModal = (
  modal: HTMLIFrameElement,
  container?: string | HTMLElement
) => {
  return new Promise<void>((resolve) => {
    let elHolder: HTMLElement;

    if (typeof container === 'string') {
      elHolder = document.querySelector(container);

      if (!elHolder) {
        throw new Error(
          `ChargeAfter: Container with selector ${container} not found`
        );
      }

      modal.setAttribute('style', getInlineIframeStyle());
    }

    if (!elHolder) {
      elHolder =
        (container instanceof HTMLElement && (container as HTMLElement)) ||
        refDocument.body;
    }

    if (!elHolder.contains(modal)) {
      elHolder.appendChild(modal);

      const onInitListen = ({ data }: MessageEvent<ModalMessagePayload>) => {
        if (
          data.source === 'CAMODAL' &&
          (data.action === 'APPLICATION_LOADED' ||
            data.action === 'APPLICATION_INIT')
        ) {
          refWindow.removeEventListener('message', onInitListen);
          resolve();
        }
      };

      refWindow.addEventListener('message', onInitListen);
    } else {
      resolve();
    }
  });
};

export const createIframe = async ({
  id,
  container,
  name,
  url,
}: CreateIframePayload): Promise<HTMLIFrameElement> => {
  logger.log(`creating modal iframe`, {
    function: 'createIframe',
    id,
    name,
    container: container && container.toString(),
    filename,
  });

  const iframe = (document.getElementById(id) ||
    refDocument.createElement('iframe')) as HTMLIFrameElement;

  iframe.setAttribute('title', 'Payment option flow');
  iframe.setAttribute('style', environment.iframe.style);
  iframe.setAttribute('name', name);
  iframe.setAttribute('id', id);
  iframe.setAttribute('src', url || environment.iframe.url);

  const onFail = (...args) => {
    throw (
      (args.length && args[args.length - 1]) ||
      new Error('modal failed to initialize')
    );
  };

  iframe.onerror = onFail;
  iframe.onabort = onFail;
  await populateContainerWithModal(iframe, container);
  return iframe;
};

export const createScript = (
  url: string,
  onLoadFailed: () => void
): HTMLScriptElement => {
  const script = document.createElement('script');

  script.setAttribute('id', environment.externalScriptId);
  script.setAttribute('src', url);
  script.setAttribute('async', 'true');
  script.async = true;
  script.onerror = onLoadFailed;
  script.onabort = onLoadFailed;

  return script;
};

export const sendMsg = (
  payload: Exclude<SDKMessagePayload, 'source'>,
  target?: HTMLIFrameElement
) => {
  logger.log(`SDK sends message`, {
    function: 'sendMsg',
    filename,
    payload,
  });
  target?.contentWindow?.postMessage(
    { ...payload, source: 'CASDK', id: target.name },
    '*'
  );
};

export const getLendersFromModalAction = createMessageFunction(
  'GET_DIRECT_LENDERS',
  ['ON_GET_DIRECT_LENDERS_RECEIVED', 'COMPLETION_CALLBACK_CALLED']
);

export const initModalAction = createMessageFunction(
  'APPLICATION_INIT',
  'APPLICATION_INIT_DONE'
);

export const openModalAction = createMessageFunction('OPEN_MODAL', [
  'CLOSE_MODAL',
  'COMPLETION_CALLBACK_CALLED',
]);

export const openEligibilityModal = createMessageFunction('OPEN_MODAL', [
  'CLOSE_MODAL',
  'COMPLETION_CALLBACK_CALLED',
]);

export const onCompletionCallbackCalled = ({
  isCheckout,
  callbackData,
  onComplete,
  presentCallback,
  widgetData,
}: {
  isCheckout: boolean;
  callbackData: MessageCallbackCalledPayload['data'];
  onComplete?: OnCompleteCallback;
  presentCallback?: Callback;
  widgetData?: OpenPromotionsModal['widget']['widgetData'];
}) => {
  logger.log(`about to call merchant callback`, {
    function: 'callMerchantCallbacks',
    filename,
    isCallbackExist: !!presentCallback,
    isOnCompleteCallbackExist: !!onComplete,
    callbackData,
    isCheckout,
  });

  try {
    const { token, data, status } = callbackData;

    const {
      widgetItemCategory,
      widgetItemPrice,
      widgetItemSku,
      widgetItemTag,
    } = widgetData || {};

    onComplete?.(token, data, status, {
      source: isCheckout ? 'CHECKOUT' : 'APPLY',
      ...(widgetData && {
        promotion: {
          category: widgetItemCategory,
          price: widgetItemPrice,
          sku: widgetItemSku,
          tag: widgetItemTag,
        },
      }),
    });

    if (isCheckout) {
      (presentCallback as CheckoutCallback)?.(
        token,
        data as CompletionCheckoutData,
        status
      );
    } else {
      (presentCallback as ApplyCallback)?.(data as CompletionApplyData, status);
    }
  } catch (error) {
    logger.error(`An error accrued while calling merchant callback`, {
      function: 'callMerchantCallbacks',
      filename,
      error,
      message: (error as Error).message,
      printOriginalToConsole: false,
      isSeverityWarn: true,
    });
  }
};

// calling merchant onDataUpdate callback
export const onUpdateDataCalled = async (
  updatedData: MessageDataUpdatePayload['updatedData'],
  isCheckout: boolean,
  onDataUpdate?: OnDataUpdate
) => {
  try {
    logger.log(`about to call merchant onDataUpdate`, {
      function: 'callMerchantDataUpdate',
      filename,
      isOnDataUpdateExist: !!onDataUpdate,
      updatedData,
      isCheckout,
    });

    let receivedData: OnDataUpdateCallBackData;
    let resolve: OnDataUpdateCallBack;

    const promise = new Promise((_resolve) => {
      resolve = _resolve;
    });

    receivedData = (await onDataUpdate?.(
      updatedData,
      resolve
    )) as OnDataUpdateCallBackData;

    if (!receivedData) {
      receivedData = await promise;
    }

    return receivedData;
  } catch (error) {
    logger.error(`An error accrued while calling merchant onDataUpdate`, {
      function: 'onUpdateDataCalled',
      filename,
      error,
      message: (error as Error).message,
    });
    return {};
  }
};

export const createModalListeners = ({
  isCheckout,
  merchantActions: {
    onConfirm,
    onDataUpdate,
    onApprovalStatusChange,
    onApplicationCreated,
    onApplicationCreatedGlobal,
  } = {},
  modalData,
  widgetData,
}: {
  modalData: ModalData;
  isCheckout: boolean;
  merchantActions?: MerchantActions;
  widgetData?: OpenPromotionsModal['widget']['widgetData'];
}) => {
  const { iframe: modal } = modalData;

  return createListenersEvents(modalData.id, modal, {
    CONFIRM: {
      onMessage: async ({
        confirmationId,
        callbackData,
      }: NarrowAction<ModalMessagePayload, 'CONFIRM'>) => {
        onConfirm?.(confirmationId, callbackData);
      },
    },
    OPEN_DBUY: {
      onMessage: async ({
        dbuyData,
      }: NarrowAction<ModalMessagePayload, 'OPEN_DBUY'>) => {
        if (dbuyData && modal) {
          new DBUY().load(dbuyData, modal);
        }
      },
    },
    OPEN_EXTERNAL_SCRIPT: {
      onMessage: async ({
        scriptInfo,
        scriptUrl,
      }: NarrowAction<ModalMessagePayload, 'OPEN_EXTERNAL_SCRIPT'>) => {
        if (scriptUrl && scriptInfo) {
          loadExternalScript(
            modalData,
            { scriptInfo, scriptUrl },
            { sendMsg, createScript }
          );
        }
      },
    },
    ON_UPDATE_DATA_CALLED: {
      responseAction: 'ON_UPDATE_DATA_CALLED',
      onMessage: async (payload: MessageDataUpdatePayload) => {
        const cartDetails = await onUpdateDataCalled(
          payload.updatedData,
          isCheckout,
          onDataUpdate
        );

        return { type: 'payments', paymentsData: { opt: { cartDetails } } };
      },
    },
    ON_APPROVAL_STATUS_CHANGE: {
      onMessage: async ({
        status,
        data,
      }: NarrowAction<ModalMessagePayload, 'ON_APPROVAL_STATUS_CHANGE'>) => {
        onApprovalStatusChange?.(status, data);
      },
    },
    ON_APPLICATION_CREATED: {
      onMessage: async ({
        applicationId,
      }: NarrowAction<ModalMessagePayload, 'ON_APPLICATION_CREATED'>) => {
        onApplicationCreated?.(applicationId);

        onApplicationCreatedGlobal?.(applicationId, {
          source: modalData.payload.type || 'APPLY',
          promotion: {
            category: widgetData?.widgetItemCategory,
            price: widgetData?.widgetItemPrice,
            sku: widgetData?.widgetItemSku,
            tag: widgetData?.widgetItemTag,
          },
        } as Parameters<OnApplicationCreatedGlobal>[1]);
      },
    },
    SET_FEATURE_FLAGS: {
      onMessage: async ({
        featureFlags: featureFlagsFromModal,
      }: NarrowAction<ModalMessagePayload, 'SET_FEATURE_FLAGS'>) => {
        setFeatureFlags(featureFlagsFromModal);
      },
    },
  });
};

export const listenToPopState = (modal: HTMLIFrameElement) => {
  const onPopState = () => {
    // this popstate will be happening only when the navigation on the merchant site happens
    // e.g if we have 2 navigation inside iframe and we click back this popstate wont fire
    // but if we click right button on mouse and select some merchant history this popstate will fire
    // in this case the popstate of the iframe wont be fired and therefore we need a way to close modal
    // on such scenario
    logger.log(`closing modal due to popstate`, {
      function: 'listenToPopState',
      filename,
    });
    modal.contentWindow.postMessage(
      {
        action: 'CLOSE_MODAL',
        type: 'payments',
        source: 'CASDK',
        id: modal.name,
      },
      '*'
    );
  };

  setTimeout(() => refWindow.addEventListener('popstate', onPopState), 100);

  return () => {
    refWindow.removeEventListener('popstate', onPopState);
  };
};
